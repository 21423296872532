<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">  {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
                {{$store.state.language.lang[8]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="m_body_title">
              <h2>  {{$store.state.language.lang[8]}}</h2>
            </div>
            <div class="row" v-if="$store.state.contact[0]">
              <div class="col-md-6">
                <div class="contact_card">
                    <div class="contact_card_f">
                      <div class="contact_text">

                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.99837 20.332C8.99837 20.332 1.23438 15.667 1.23438 9.44402C1.23438 5.14802 4.71637 1.66602 9.01237 1.66602C13.3084 1.66602 16.7904 5.14802 16.7904 9.44402C16.7904 15.667 8.99837 20.332 8.99837 20.332Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M9.01237 12.556C10.7305 12.556 12.1234 11.1632 12.1234 9.44502C12.1234 7.72686 10.7305 6.33402 9.01237 6.33402C7.29422 6.33402 5.90137 7.72686 5.90137 9.44502C5.90137 11.1632 7.29422 12.556 9.01237 12.556Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
</div>
                      <div class="contact_description">  {{$store.state.contact[0].address}}</div>
                    </div>
                  <div class="contact_card_f">
                    <div class="contact_text">

                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.959 14.068V15.066M7.04101 14.959V14.068M4.00001 11.528H20M1.33301 5.83398V8.48898M22.667 5.83398V8.48898M8.11301 17.821V19.397C8.11301 20.196 7.46501 20.843 6.66701 20.843C5.86801 20.843 5.22101 20.195 5.22101 19.397V17.045M15.887 17.821V19.397C15.887 20.196 16.535 20.843 17.333 20.843C18.132 20.843 18.779 20.195 18.779 19.397V17.045M14.367 14.959H9.63301M17 17.634H7.00001C5.34301 17.634 4.00001 16.291 4.00001 14.634V6.15698C4.00001 4.49998 5.34301 3.15698 7.00001 3.15698H17C18.657 3.15698 20 4.49998 20 6.15698V14.634C20 16.291 18.657 17.634 17 17.634Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg></div>
                    <div class="contact_description">  {{$store.state.contact[0].by_bus}}</div>
                  </div>
                  <div class="contact_card_f">
                    <div class="contact_text">

                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.33316 21.332L6.66716 18.665M17.3332 18.665L18.6672 21.332M8.00016 14.667V13.775M16.0002 14.667V13.775M4.80016 8.48897H19.2002M12.0002 8.48897V2.66797M4.39616 6.62597C4.18416 4.50697 5.84816 2.66797 7.97816 2.66797H16.0222C18.1522 2.66797 19.8162 4.50697 19.6042 6.62597V14.707C19.8162 16.826 18.1522 18.665 16.0222 18.665H7.97816C5.84816 18.665 4.18416 16.826 4.39616 14.707V6.62597Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="contact_description">{{$store.state.contact[0].by_metro}}</div>
                  </div>
                  <div class="contact_card_f">

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.334 12.001C21.334 17.1554 17.1554 21.334 12.001 21.334M21.334 12.001C21.334 6.8465 17.1554 2.66797 12.001 2.66797M21.334 12.001H18.666M12.001 21.334C6.8465 21.334 2.66797 17.1554 2.66797 12.001M12.001 21.334V18.667M2.66797 12.001C2.66797 6.8465 6.8465 2.66797 12.001 2.66797M2.66797 12.001H5.33597M12.001 2.66797V5.33597M12 12.0039L12.0018 12.001" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div class="contact_description">
                      {{$store.state.contact[0].reference_point}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="contact_card">
                  <div class="contact_title">  {{$store.state.language.lang[50]}}</div>
                  <div class="contact_card_f">
                    <div class="contact_text">{{$store.state.contact[0].working_days}} </div>
                  </div>
                  <div class="contact_card_f">
                    <div class="contact_text">{{$store.state.contact[0].lunch}} </div>
                  </div>
                  <div class="contact_card_f">
                    <div class="contact_text">{{$store.state.contact[0].weekend}} </div>
                  </div>
<!--                  <div class="contact_card_f">-->
<!--                    <div class="contact_text">Обеденный перерыв: </div>-->
<!--                    <div class="contact_description">   с 13:00 по 14:00</div>-->
<!--                  </div>-->
<!--                  <div class="contact_card_f">-->
<!--                    <div class="contact_text">Выходные:</div>-->
<!--                    <div class="contact_description">  Суббота, Воскресенье</div>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="col-md-12" v-if="$store.state.contact[0]">
                <div class="yandex_map">
                  <l-map :options="{scrollWheelZoom: false}" style="height: 484px;width: 100%;border-radius: 5px" :zoom="zoom" :center="[$store.state.contact[0].latitude,$store.state.contact[0].longitude]">
                    <l-tile-layer :url="url"></l-tile-layer>

                    <l-marker :draggable="false" :lat-lng="[$store.state.contact[0].latitude,$store.state.contact[0].longitude]">
                      <l-icon
                          :icon-size="[30,35]"
                          :icon-url="img_icon"
                          :shadow-url="require('@/assets/marker-shadow.png')"
                      />
                    </l-marker>
                  </l-map>
                </div>
                <div class="contact_card2" v-if="$store.state.contact[0]">
                  <div class="col-12">

                    <div class="contact_card">
                      <div class="contact_title ">{{$store.state.language.lang[56]}} </div>
                      <div>
                        <div class="contact_text childs_cont">{{$store.state.language.lang[91]}}</div>
                        <div class="contact_description">{{$store.state.contact[0].first_phone_number}}</div>
                      </div>
                      <div class="row">
                        <div class=" col-md-3 ">
                          <div class="contact_text childs_cont">{{ $store.state.language.lang[89] }} </div>
                          <div class="contact_description">{{$store.state.contact[0].second_phone_number}}</div>
                        </div>
                        <div class=" col-md-3 ">
                          <div class="contact_text childs_cont">{{$store.state.language.lang[90]}}  </div>
                          <div class="contact_description">{{$store.state.contact[0].third_phone_number}}</div>
                        </div>
                        <div class="  col-md-3 ">
                          <div class="contact_text childs_cont">{{$store.state.language.lang[93]}} </div>
                          <div class="contact_description">{{$store.state.contact[0].fax}}</div>
                        </div>
                        <div class="  col-md-3 ">
                          <div class="contact_text childs_cont">{{$store.state.language.lang[92]}}</div>
                          <div class="contact_description"> {{$store.state.contact[0].email}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";

import {LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet';
import icon from "@/assets/img/maps_icon.png";
export default {
  components: {
    Footer,
    Navbar,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      isActive_m: false,
      img_icon: icon,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: [39.652451, 66.970139],
      markerLatLng: [39.652451, 66.970139],
      lat: "",
      lng: "",
    }
  },
  mounted() {
    this.$store.dispatch('contactFunck')
  },
  methods: {
    open_modal() {

    }
  }
}
</script>
